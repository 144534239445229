<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="店铺名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入店铺名称"></el-input>
      </el-form-item>
      <el-form-item label="店铺LOGO" prop="logo">
        <SelectImgs :selectNum="1" btnTitle="选择LOGO" :selectData="[info.logo]" :selectFunc="picChoose" :idx="1"></SelectImgs>
      </el-form-item>
      <el-form-item label="分享图" prop="logo">
        <SelectImgs :selectNum="1" btnTitle="选择分享图" :selectData="[info.share_pic]" :selectFunc="picChoose" :idx="2"></SelectImgs>
      </el-form-item>
      <el-form-item label="客服电话">
        <el-input v-model="info.phone" placeholder="请输入客服电话"></el-input>
      </el-form-item>
      <el-form-item label="省市区" prop="city">
        <el-cascader :options="cityTree" v-model="city" separator="-"></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="info.address" placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <el-form-item label="店长姓名">
        <el-input v-model="info.contact_name" placeholder="请输入店长姓名"></el-input>
      </el-form-item>
      <el-form-item label="店长手机">
        <el-input v-model="info.contact_mobile" placeholder="请输入店长手机"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
  export default {
    data() {
      return {
        info: {
          title: '',
          contact_name: '',
          contact_mobile: '',
          address: '',
          logo: '',
          phone: '',
          share_pic: ''
        },
        cityTree: [],
        city: [],
        source_domain: this.config.SOURCE_DOMAIN,
        rules: {
          title: [
            { required: true, message: '请输入店铺名称', trigger: 'blur' }
          ]
        }
      };
    },
    components: {
      SelectImgs
    },
    created() {
      this.getCityTree()
      this.getInfo()
    },
    mounted() {
    },
    computed: {
    },
    methods: {
      picChoose(data,idx) {
        if(idx==1) {
          this.info.logo = data.pic
        } else {
          this.info.share_pic = data.pic
        }
      },
      getCityTree() {
        this.$api.merchant.cityTree(res => {
          if(res.errcode == 0) {
            this.cityTree = res.data
          }
        })
      },
      getInfo() {
        var that = this
        this.$api.shop.shopInfo( {},function (res) {
          if(res.errcode == 0) {
            that.info = res.data
            that.city = [that.info.province,that.info.city,that.info.area]
          }
        })
      },
      submitForm(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = that.info
            param.province = that.city[0]
            param.city = that.city[1]
            param.area = that.city[2]
            this.$api.shop.shopEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
</style>
